<template>
  <div id="newsStoryContent">
    <!-- pic7681F4EE-7FEA-41FC-B88D-B51F6F7DAA45.jpg -->
    <img id="Image1" style="width: 133px; height: 100px;" src="@/assets/news-network/behind-the-scenes-with-lego-fan-small.jpg" class="rounded">
    <p id="Text1">
      Meet Steve Barker, who has been a LUP (LEGO® Universe Partner) since the very early days of LEGO® Universe.
      <br><br>
      Steve is a huge LEGO fan and a part of the AFOL (Adult Fans of LEGO) community.
      His great passion has led to an entire room in his house filled with colorful bricks,
      and he spends most of his free time focusing on building what he sees in his mind,
      adding new content to the game.
      <br><br>
      He’s a firm believer that the hobby of playing with small plastic toy bricks
      is keeping the child alive in him and just like Peter Pan he never quite grew up completely.
      <br><br>
      His story is a true inspiration and his journey with LEGO Universe has been an amazing
      ride so far! Dive into his world and hear him tell all about it in this new
      <a href="/media/section/148275/category/149651">video</a>.
    </p>
  </div>
</template>
